'use client';
import React, { type ReactElement } from 'react';

import isEqual from 'lodash/isEqual.js';

import { Notification } from '../Notification';

interface IProps {
  children: React.ReactNode;
}

interface INotification {
  message: string;
  variant: ENotificationVariant;
}

export const ENotificationVariant = {
  WARNING: 'warning',
  SUCCESS: 'success',
  STANDARD: 'info',
  ERROR: 'error',
} as const;

export type ENotificationVariant =
  (typeof ENotificationVariant)[keyof typeof ENotificationVariant];

interface INotificationContextValue {
  setSuccessNotification: (message: string) => void;
  setErrorNotification: (message: string) => void;
}

const defaultNotificationContextValue: INotificationContextValue = {
  setSuccessNotification: () => null,
  setErrorNotification: () => null,
};

export const NotificationContext =
  React.createContext<INotificationContextValue>(
    defaultNotificationContextValue
  );

export const NotificationProvider: React.FC<IProps> = ({ children }) => {
  const [notification, setNotificationInternal] =
    React.useState<INotification | null>(null);

  const setNotification = React.useCallback(
    (newNotification: INotification | null) => {
      if (!isEqual(notification, newNotification)) {
        setNotificationInternal(newNotification);
      }
    },
    [notification, setNotificationInternal]
  );

  const clearNotification = React.useCallback(() => {
    setNotification(null);
  }, [setNotification]);

  const setSuccessNotification = React.useCallback(
    (message: string) => {
      setNotification({
        variant: ENotificationVariant.SUCCESS,
        message: message,
      });
    },
    [setNotification]
  );

  const setErrorNotification = React.useCallback(
    (message: string) => {
      setNotification({
        variant: ENotificationVariant.ERROR,
        message: message,
      });
    },
    [setNotification]
  );

  const notificationContextApi = {
    setSuccessNotification,
    setErrorNotification,
  };
  return (
    <NotificationContext.Provider value={notificationContextApi}>
      {children}
      {notification && (
        <Notification
          notification={notification.variant}
          message={notification.message}
          handleClose={clearNotification}
        />
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): INotificationContextValue => {
  return React.useContext(NotificationContext);
};

export const notificationComponent = (
  props: React.ComponentProps<typeof Notification>
): ReactElement => {
  return <Notification {...props} />;
};
